import type { FC } from "react"
import { useState } from "react"
import type { ButtonProps } from "@chakra-ui/react"
import { useDisclosure, Button } from "@chakra-ui/react"
import { isAddress } from "@ethersproject/address"

import DelegateModal from "delegation/components/DelegateModal"
import { useNavegableModal } from "common/hooks/useNavegableModal"
import { useConnectAccount } from "web3/components/ConnectAccountButton"
import type { Account, Organization } from "query/graphql" // Import AssetID
import WalletSelectionModalV2 from "web3/components/WalletSelectionModalV2"
import WalletSelectionModal from "web3/components/WalletSelectionModal"
import { useFeatureFlag } from "common/hooks/useFeatureFlag"
import { FeatureFlag } from "common/types/featureflags"
import { useSignerStore } from "web3/providers/SignerProvider"
import PendingTransactionModal from "governance/components/PendingSafeTransactionModal"
import { useMultichainAccount } from "web3/hooks/useMultichainAccount"
import { isSolanaAddress } from "web3/helpers/address"

const DelegateButton: FC<
  {
    organization: Organization
    delegateAddress?: string
    account?: Pick<Account, "address" | "bio" | "name" | "picture" | "twitter">
    slug?: string
    dataQa?: string
    defaultTokenId?: string
    redirectTo?: string
    modalTitle?: string
    buttonLabel?: string
    balance?: string
    onWrongNetworkOpen?: () => void
  } & ButtonProps
> = ({
  organization,
  delegateAddress,
  children,
  account,
  slug,
  dataQa,
  defaultTokenId,
  redirectTo,
  buttonLabel,
  modalTitle,
  balance,
  onWrongNetworkOpen,
  ...buttonProps
}) => {
  // If defaultTokenId is received, then use this tokenId, otherwise use the organization tokenIds
  const tokenIds = Boolean(defaultTokenId)
    ? [defaultTokenId]
    : organization.tokenIds

  const [isNavegableModalActive, setIsNavegableModalActive] =
    useState<boolean>(false)

  const { multichainAccount } = useMultichainAccount()
  const { address } = multichainAccount
  const { signer } = useSignerStore()
  const { onOpen, addStep, NavegableModal } = useNavegableModal()
  const walletSelectionDisclousure = useDisclosure()
  const pendingTransactionModal = useDisclosure()
  const { isFeatureFlagOn } = useFeatureFlag()

  const connectAccount = useConnectAccount({
    onOpen: walletSelectionDisclousure.onOpen,
    shouldPromptSiwe: false,
  })

  const handleDelegateClick = (): void => {
    addStep({
      title: modalTitle ?? "Delegate",
      Component: DelegateModal,
      props: {
        account,
        organization,
        delegateeAddress: delegateAddress,
        tokenIds,
        redirectTo,
        openPendingTransaction: pendingTransactionModal.onOpen,
        slug,
        buttonLabel,
        balance,
      },
    })
    setIsNavegableModalActive(true)
    onOpen()
  }

  return (
    <>
      <Button
        {...buttonProps}
        data-qa={dataQa ? dataQa : "delegation-delegate-btn"}
        isDisabled={Boolean(buttonProps.disabled)}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          if (
            isSolanaAddress(address) &&
            delegateAddress &&
            isAddress(delegateAddress) &&
            onWrongNetworkOpen
          ) {
            onWrongNetworkOpen()

            return
          }

          if (!address || !signer) {
            connectAccount()

            return
          }

          handleDelegateClick()
        }}
      >
        {children}
      </Button>

      <NavegableModal
        isActive={isNavegableModalActive}
        setIsActive={setIsNavegableModalActive}
      />
      {isFeatureFlagOn(FeatureFlag.SolanaConnect) ? (
        <WalletSelectionModalV2
          isOpen={walletSelectionDisclousure.isOpen}
          onClose={() => walletSelectionDisclousure.onClose()}
        />
      ) : (
        <WalletSelectionModal
          isOpen={walletSelectionDisclousure.isOpen}
          onClose={() => walletSelectionDisclousure.onClose()}
        />
      )}
      <PendingTransactionModal
        isOpen={pendingTransactionModal.isOpen}
        onClose={pendingTransactionModal.onClose}
      />
    </>
  )
}

export default DelegateButton
